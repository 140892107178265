import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import Log from './Log'

const GET_LOGS = gql`
  query Get_Logs {
    logs {
      id
      type
      createdAt
      source
      ip
      filename
    }
  }
`

const Logs = () => (
  <Query query={GET_LOGS}>
    {({ loading, error, data }) => {
      if (loading) return 'Wird geladen...'
      if (error) return 'Fehler :('

      return (
        <>
          {data.logs.map(log => (
            <Log key={log.id} {...log} />
          ))}
        </>
      )
    }}
  </Query>
)

export default Logs
