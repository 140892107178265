import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import axios from 'axios'
import styled, { css } from 'styled-components'

import Loading from './Loading'
import Title from './Title'
import UploadHistory from './UploadHistory'
import UploadFile from './UploadFile'
import * as authService from '../utils/authService'

const Droparea = styled.div`
  outline: none;
`

const Area = styled.div`
  padding: 20px;
  border: 2px dashed gray;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.25s;
  outline: none;

  ${props =>
    props.isActive &&
    css`
      border-width: 4px;
      background: #f7f7f7;
    `}
`

class Upload extends Component {
  state = {
    upload: false,
    loading: false,
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    // TODO: Send to server.
    let formData = new FormData()
    acceptedFiles.forEach(file => {
      formData.append('File[]', file)
    })

    const token = authService.getToken()
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    this.setState({
      loading: true,
    })

    // Make available with and without https.
    let endpoint = process.env.REACT_APP_ENDPOINT
    if (window.location.href.indexOf('http://') > -1) {
      endpoint = endpoint.replace('https://', 'http://')
    }

    axios
      .post(`${endpoint}/upload`, formData, config)
      .then(response => {
        this.setState({
          upload: response.data,
          loading: false,
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    return (
      <>
        {this.state.upload && (
          <>
            <Title>Download</Title>
            <UploadFile
              code={this.state.upload.code}
              url={this.state.upload.url}
              daysAvailable={this.state.upload.daysAvailable}
              files={this.state.upload.files}
            />
          </>
        )}

        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <Droparea
                {...getRootProps()}
                className={classNames('dropzone', {
                  'dropzone--is-active': isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <Area isActive={isDragActive}>
                  {this.state.loading ? (
                    <Loading color="dark" />
                  ) : (
                    <div>
                      Ziehe die Dateien hierher, oder klicke{' '}
                      <span style={{ textDecoration: 'underline' }}>hier</span>.
                    </div>
                  )}
                </Area>
              </Droparea>
            )
          }}
        </Dropzone>
        <UploadHistory />
      </>
    )
  }
}

export default Upload
