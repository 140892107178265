import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import UserEditForm from './UserEditForm'

const GET_USER_QUERY = gql`
  query($id: String!) {
    user(id: $id) {
      id
      firstname
      lastname
      email
      isAdmin
    }
  }
`

const UserEdit = ({ match }) => (
  <Query query={GET_USER_QUERY} variables={{ id: match.params.userId }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>
      if (error) return <p>Fehler :(</p>

      return <UserEditForm user={data.user} />
    }}
  </Query>
)

export default UserEdit
