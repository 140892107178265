import styled from 'styled-components'

const Button = styled.button`
  margin: 0;
  padding: 14px 32px;
  background-color: #ffe000;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  font-family: inherit;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  outline: none;
  text-decoration: none;
`

export default Button
