import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as authService from '../utils/authService'

const StyledLink = styled(Link)`
  color: inherit;
`

const LogoutButton = styled.button`
  margin: 0;
  padding: 6px;
  cursor: pointer;
  font-size: 18px;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  top: 1px;
`

class User extends React.Component {
  onLogout = () => {
    authService.logout()
    this.props.history.push('/')
  }

  render() {
    return (
      <div>
        {authService.isAuthenticated() && (
          <div>
            {authService.isAdmin() && (
              <>
                <StyledLink to="/admin">Admin</StyledLink>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            )}
            {authService.getName()} &nbsp;|&nbsp;
            <LogoutButton onClick={this.onLogout}>
              <FontAwesomeIcon icon={['far', 'sign-out-alt']} />
            </LogoutButton>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(User)
