/**
 * STORAGE_VALUE: { name: 'Firstname', token: 'xyz' }
 */

const NAME_KEY = 'maq-name'
const ID_KEY = 'maq-id'
const TOKEN_KEY = 'maq-token'
const ISADMIN_KEY = 'maq-isadmin'

export function logout() {
  sessionStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem(NAME_KEY)
  sessionStorage.removeItem(ID_KEY)
  sessionStorage.removeItem(ISADMIN_KEY)
}

export function login(token, user) {
  sessionStorage.setItem(TOKEN_KEY, token)
  sessionStorage.setItem(ID_KEY, user.id)
  sessionStorage.setItem(NAME_KEY, user.firstname)
  sessionStorage.setItem(ISADMIN_KEY, user.isAdmin)
}

export function getName() {
  const name = sessionStorage.getItem(NAME_KEY)
  if (name !== null) {
    return name
  }

  return
}

export function getID() {
  const id = sessionStorage.getItem(ID_KEY)
  if (id !== null) {
    return id
  }

  return
}

export function getToken() {
  return sessionStorage.getItem(TOKEN_KEY)
}

export function isAdmin() {
  const isAdmin = sessionStorage.getItem(ISADMIN_KEY)
  if (!isAdmin) {
    return false
  }

  return true
}

export function isAuthenticated() {
  const data = sessionStorage.getItem(TOKEN_KEY)
  if (data === null) {
    return false
  }

  return true
}
