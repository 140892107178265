import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  border-bottom: 2px solid #f5f5f5;
  padding: 12px 0;
  display: flex;

  &:last-of-type {
    border-bottom: 0;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

const Icon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background: #eaeaea;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  ${props =>
    props.focus &&
    css`
      background: #ffe000;
    `}
`

const Date = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-right: 24px;
`

const Main = styled.div`
  flex: auto;
  font-size: 15px;
  color: #222;
`

const Actions = styled.div`
  margin-left: auto;
`

const Title = styled.h2`
  font-size: 15px;
  color: #222;
  font-weight: 400;
  margin: 0;
`

const Meta = styled.div`
  font-size: 15px;
  color: #222;
  opacity: 0.6;
`

const Item = ({
  icon,
  title,
  meta,
  actions,
  focus,
  disabled,
  date,
  children,
}) => (
  <Wrapper disabled={disabled}>
    <Icon focus={focus}>{icon}</Icon>
    {date && <Date>{date}</Date>}
    <Main>
      <Title>{title}</Title>
      <Meta>{meta}</Meta>
      {children}
    </Main>
    <Actions>{actions}</Actions>
  </Wrapper>
)

export default Item
