import styled, { css } from 'styled-components'

const Input = styled.input`
  border-radius: 3px;
  background: #f1f1f1;
  border: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  padding: 14px;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  color: #000;
  max-width: 100%;
  width: 260px;

  ${props =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`

export default Input
