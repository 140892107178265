import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.label`
  font-family: inherit;
  font-size: 16px;
`

const Input = styled.input`
  position: relative;
  top: -1px;
`

const Checkbox = ({ onChange, isChecked, label }) => (
  <Wrapper>
    <Input type="checkbox" checked={isChecked} onChange={onChange} /> {label}
  </Wrapper>
)

export default Checkbox
