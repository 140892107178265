import React from 'react'
import styled from 'styled-components'
import { Route, Redirect, NavLink, Switch } from 'react-router-dom'

import Users from './Users'
import UserAdd from './UserAdd'
import UserEdit from './UserEdit'
import Logs from './Logs'

const Container = styled.div``

const Nav = styled.nav`
  margin-bottom: 4rem;
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`

const NavItem = styled.li``

const StyledNavLink = styled(NavLink)`
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 10px 0;
  border-bottom: 4px solid #f5f5f5;
  font-size: 32px;
  font-weight: 300;
  width: 200px;

  &.active {
    border-bottom-color: #444;
  }
`
const NavText = styled.span``

const Admin = ({ match }) => (
  <Container>
    <Nav>
      <NavList>
        <NavItem>
          <StyledNavLink to={`${match.path}/logs`}>
            <NavText>Logs</NavText>
          </StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink to={`${match.path}/users`}>
            <NavText>Benutzer</NavText>
          </StyledNavLink>
        </NavItem>
      </NavList>
    </Nav>
    <Switch>
      <Redirect exact from={`${match.path}`} to={`${match.path}/logs`} />
      <Route path={`${match.path}/users`} exact component={Users} />
      <Route path={`${match.path}/users/add`} exact component={UserAdd} />
      <Route
        path={`${match.path}/users/:userId/edit`}
        exact
        component={UserEdit}
      />
      <Route path={`${match.path}/logs`} exact component={Logs} />
    </Switch>
  </Container>
)

export default Admin
