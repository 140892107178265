import React, { Component } from 'react'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
} from 'react-router-dom'

import logo from './assets/q-logo.svg'
import * as authService from './utils/authService'
import Login from './components/Login'
import Upload from './components/Upload'
import User from './components/User'
import Help from './components/Help'
import Admin from './components/Admin/Admin'

const Container = styled.div`
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 500px;
    height: 500px;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0.05;
    background: url(${logo}) left bottom no-repeat;
    background-size: 500px auto;
    background-position: left -150px bottom -20px;
    z-index: -1;
  }
`

const Header = styled.header`
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  top: 0;
  width: 100%;
`

const HeaderLeft = styled.div``

const HeaderRight = styled.div`
  margin-left: auto;
`

const TitleLink = styled(Link)`
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  color: inherit;
  text-decoration: none;
`

const Content = styled.div`
  /* height: 100vh; /** needed for IE 11 to center align. */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: 1.2rem;
  max-width: 860px;
  margin: 0 auto;
  padding: 128px 48px;
`

const isAllowed = onlyAdmin => {
  if (!authService.isAuthenticated()) {
    return false
  }

  if (onlyAdmin && authService.isAdmin() !== true) {
    return false
  }

  return true
}

const AuthRoute = ({ component: Component, onlyAdmin = false, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAllowed(onlyAdmin) ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
)

class App extends Component {
  render() {
    return (
      <Router>
        <Container>
          <Header>
            <HeaderLeft>
              <TitleLink to="/">Datenaustausch</TitleLink>
            </HeaderLeft>
            <HeaderRight>
              <User />
            </HeaderRight>
          </Header>
          <Content>
            <Route path="/login" exact component={Login} />
            <AuthRoute path="/" exact component={Upload} />
            <Route path="/help" exact component={Help} />
            <AuthRoute path="/admin" onlyAdmin component={Admin} />
          </Content>
        </Container>
      </Router>
    )
  }
}

export default App
