import React from 'react'
import styled, { css } from 'styled-components'
import copy from 'copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 4rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
`

const CopyTarget = styled.div`
  margin: 0;
  padding: 12px 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  resize: none;
  border: 0;
  width: 100%;
  max-width: 100%;
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
`

const CopyButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 18px;
  color: #444;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyInfo = styled.div`
  opacity: 0;
  font-size: 12px;
  margin-right: 8px;
  color: #222;
  transition: opacity 0.4s;

  ${props =>
    props.isCopied &&
    css`
      opacity: 1;
    `}
`

const CopyIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

class UploadFile extends React.Component {
  state = {
    isCopied: false,
  }

  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
  }

  copyToClipboard = () => {
    const content = this.contentRef.current.innerText
    copy(content, {
      format: 'text/plain',
    })

    this.setState({
      isCopied: true,
    })

    setTimeout(() => {
      this.setState({
        isCopied: false,
      })
    }, 2000)
  }

  render() {
    const { code, url, daysAvailable, files } = this.props

    return (
      <Wrapper>
        <CopyButton onClick={this.copyToClipboard}>
          <CopyInfo isCopied={this.state.isCopied}>
            In Zwischenablage kopiert...
          </CopyInfo>
          <CopyIcon>
            <FontAwesomeIcon icon={['far', 'copy']} />
          </CopyIcon>
        </CopyButton>

        <CopyTarget contenteditable="true" disabled ref={this.contentRef}>
          Code: {code}
          <br />
          Download: <a href={url}>{url}</a>
          <br />
          Gültigkeit: {daysAvailable} Tage
          <br />
          Inhalt:
          {files.map((filename, index) => (
            <div key={index}>•&nbsp;&nbsp;{filename}</div>
          ))}
        </CopyTarget>
      </Wrapper>
    )
  }
}

export default UploadFile
