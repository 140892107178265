// Polyfills
import 'unfetch/polyfill'
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faTrash,
  faPen,
  faSignOut,
  faSignOutAlt,
  faArrowAltFromBottom,
  faArrowAltToBottom,
  faCopy,
  faFileUpload,
  faFileDownload,
} from '@fortawesome/pro-regular-svg-icons'

import './index.css'
import App from './App'
import * as authService from './utils/authService'
import * as serviceWorker from './serviceWorker'

library.add(
  faUser,
  faTrash,
  faPen,
  faSignOut,
  faSignOutAlt,
  faArrowAltFromBottom,
  faArrowAltToBottom,
  faCopy,
  faFileUpload,
  faFileDownload
)

const token = authService.getToken()

// Make available with and without https.
let endpoint = process.env.REACT_APP_ENDPOINT
if (window.location.href.indexOf('http://') > -1) {
  endpoint = endpoint.replace('https://', 'http://')
}

const client = new ApolloClient({
  uri: `${endpoint}/graphql`,
  headers: {
    authorization: token ? `Bearer ${token}` : '',
  },
  /*
  request: operation => {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
  */
})

const Root = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)
ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
