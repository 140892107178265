import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { format as formatDate, isAfter } from 'date-fns'

import Item from './Item'
import * as authService from '../utils/authService'

const DOWNLOADS_QUERY = gql`
  query Downloads {
    downloads {
      id
      code
      uploadedAt
      availableUntil
      files {
        id
        filename
      }
    }
  }
`

const Wrapper = styled.div`
  margin: 4rem 0;
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 300;
  margin: 0 0 1rem 0;
  padding: 0;

  small {
    font-size: 18px;
    opacity: 0.5;
    font-weight: 400;
  }
`

const FileList = styled.ul`
  margin: 0;
`

const userId = authService.getID() * 1

let reload = 0

const UploadHistory = () => (
  <Wrapper>
    <Title>
      Verlauf <small>(30 Tage)</small>
    </Title>
    <Query query={DOWNLOADS_QUERY} variables={{ userId }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>
        if (error) {
          if (reload <= 2) {
            window.location.reload(false)
            reload = reload + 1
          }
          return <p>Fehler :(</p>
        }

        return data.downloads.map(download => (
          <Item
            key={download.id}
            icon={<FontAwesomeIcon icon={['far', 'arrow-alt-from-bottom']} />}
            title={download.code}
            /* meta="25.07.2018 14:30 Uhr / Gültig bis: 05.08.2018 14:30 Uhr" */
            meta={`${formatDate(
              download.uploadedAt,
              'DD.MM.YYYY hh:mm'
            )} Uhr / Gültig bis: ${formatDate(
              download.availableUntil,
              'DD.MM.YYYY hh:mm'
            )}`}
            disabled={isAfter(Date.now(), download.availableUntil)}
          >
            {download.files && (
              <FileList>
                {download.files.map(file => (
                  <li key={file.id}>{file.filename}</li>
                ))}
              </FileList>
            )}
          </Item>
        ))
      }}
    </Query>
  </Wrapper>
)

export default UploadHistory
