import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import * as authService from '../utils/authService'
import logoImg from '../assets/marquart-logo.svg'
import Button from './Button'
import Input from './Input'

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        firstname
        lastname
        email
        isAdmin
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  margin-left: -2rem;

  @media (max-width: 600px) {
    display: block;
  }
`

const Meta = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 600px) {
    margin-bottom: 2rem;
  }
`

const Main = styled.div`
  flex: 1;
  padding-left: 2rem;
`

const Row = styled.div`
  margin-bottom: 1.25rem;

  ${props =>
    props.align === 'right' &&
    css`
      text-align: right;
    `}
`

const Error = styled.div`
  color: #e74c3c;
  font-weight: bold;
  font-size: 16px;
`

const HelpLink = styled(Link)`
  color: inherit;
  font-size: 14px;
`

const LogoImg = styled.img`
  display: block;
  margin-top: 5px;
`

class Login extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
    error: false,
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  onLoginUpdate = (cache, { data: { login } }) => {
    authService.login(login.token, login.user)

    this.setState({
      email: '',
      password: '',
    })

    this.props.history.push('/')
  }

  render() {
    const { onChange } = this
    const { email, password } = this.state

    return (
      <Container>
        <Meta>
          <Link to="/">
            <LogoImg src={logoImg} alt="MARQUART Logo" />
          </Link>
        </Meta>
        <Main>
          <Mutation mutation={LOGIN} update={this.onLoginUpdate}>
            {(login, { data, error }) => {
              return (
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    login({ variables: { email, password } })
                  }}
                >
                  <Row>
                    <Input
                      type="email"
                      placeholder="E-Mail-Adresse"
                      value={email}
                      onChange={e => onChange('email', e.target.value)}
                    />
                  </Row>
                  <Row>
                    <Input
                      type="password"
                      placeholder="Passwort"
                      value={password}
                      onChange={e => onChange('password', e.target.value)}
                    />
                  </Row>
                  <Row align="left">
                    <Button type="submit">Login</Button>
                  </Row>
                  <Row align="left">
                    <HelpLink to="/help">Passwort vergessen?</HelpLink>
                  </Row>
                  {error && (
                    <Row>
                      <Error>{error.graphQLErrors[0].message}</Error>
                    </Row>
                  )}
                </form>
              )
            }}
          </Mutation>
        </Main>
      </Container>
    )
  }
}

export default Login
