import React from 'react'
import { format as formatDate } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Item from '../Item'

const Log = ({ type, createdAt, filename, ip, source }) => {
  return (
    <Item
      icon={
        <FontAwesomeIcon
          icon={[
            'far',
            type === 'UPLOAD' ? 'arrow-alt-from-bottom' : 'arrow-alt-to-bottom',
          ]}
        />
      }
      date={formatDate(createdAt, 'DD.MM.YYYY HH:mm')}
      title={`${ip} (${source})`}
      meta={filename}
    />
  )
}

export default Log
