import styled from 'styled-components'

const Title = styled.h1`
  font-size: 32px;
  font-weight: 300;
  margin: 0 0 1rem 0;
  padding: 0;

  small {
    font-size: 18px;
    opacity: 0.5;
    font-weight: 400;
  }
`

export default Title
