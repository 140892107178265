import React from 'react'
import styled, { css } from 'styled-components'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'

import Input from '../Input'
import Button from '../Button'
import Checkbox from '../Checkbox'

const Row = styled.div`
  display: flex;
  margin: 0 -12px 24px -12px;
`

const Col = styled.div`
  flex: 1;
  padding: 0 12px;

  ${props =>
    props.align === 'right' &&
    css`
      text-align: right;
    `}
`

const Hint = styled.div`
  font-size: 14px;
  padding: 4px 2px;
  font-style: italic;
`

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: String!
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String
    $isAdmin: Boolean
  ) {
    updateUser(
      id: $id
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      isAdmin: $isAdmin
    ) {
      firstname
      lastname
      email
      isAdmin
    }
  }
`

const GET_USERS = gql`
  {
    users {
      id
      firstname
      lastname
      email
      isAdmin
    }
  }
`

class UserEdit extends React.Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    isAdmin: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      id: this.props.user.id + '',
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
      email: this.props.user.email,
      password: '',
      isAdmin: this.props.user.isAdmin,
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  onChangeAdmin = () => {
    this.setState({
      isAdmin: !this.state.isAdmin,
    })
  }

  onAddUpdate = (cache, { data }) => {
    this.props.history.push('/admin/users')
  }

  render() {
    const { onChange, onAddUpdate } = this
    const { id, firstname, lastname, email, password, isAdmin } = this.state

    return (
      <Mutation
        mutation={UPDATE_USER_MUTATION}
        refetchQueries={[{ query: GET_USERS }]}
        update={onAddUpdate}
      >
        {(addUser, { data, error }) => (
          <form
            onSubmit={e => {
              e.preventDefault()
              addUser({
                variables: {
                  id,
                  firstname,
                  lastname,
                  email,
                  password,
                  isAdmin,
                },
              })
            }}
          >
            <Row>
              <Col>
                <Input
                  type="text"
                  placeholder="Vorname"
                  fullwidth
                  value={this.state.firstname}
                  onChange={e => onChange('firstname', e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  type="text"
                  placeholder="Nachname"
                  fullwidth
                  value={this.state.lastname}
                  onChange={e => onChange('lastname', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="email"
                  placeholder="E-Mail"
                  fullwidth
                  value={this.state.email}
                  onChange={e => onChange('email', e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  type="password"
                  placeholder="Passwort"
                  fullwidth
                  value={this.state.password}
                  onChange={e => onChange('password', e.target.value)}
                />
                <Hint>Passwort leer lassen, um es nicht zu ändern.</Hint>
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  onChange={this.onChangeAdmin}
                  isChecked={!!this.state.isAdmin}
                  label="Admin"
                />
              </Col>
            </Row>
            <Row>
              <Col align="right">
                <Button type="submit">Aktualisieren</Button>
              </Col>
            </Row>
          </form>
        )}
      </Mutation>
    )
  }
}

export default withRouter(UserEdit)
