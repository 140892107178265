import React from 'react';

const Help = () => (
  <div>
    <h1>Fragen?</h1>
    <p>
      Bei Fragen, Problemen oder falls du dein Passwort vergessen hast, melde
      dich bitte bei Stephan Broder (
      <a href="mailto:s.broder@maq.ch">s.broder@maq.ch</a>).
    </p>
  </div>
);

export default Help;
