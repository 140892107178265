import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const ripple = keyframes`
  0% {
    top: 14px;
    left: 14px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 29px;
    height: 29px;
    opacity: 0;
  }
`;

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  margin: -10px 0 -10px -10px;

  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }

  ${props =>
    props.color === 'dark' &&
    css`
      div {
        border-color: #000;
      }
    `}
`;

const Loading = ({ color }) => (
  <Spinner color={color}>
    <div />
    <div />
  </Spinner>
);

export default Loading;
