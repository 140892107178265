import React from 'react'
import styled from 'styled-components'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Item from '../Item'
import Button from '../Button'

const Actions = styled.div`
  margin-bottom: 3rem;
  text-align: right;
  margin-top: -1rem;
`

const Action = styled.span`
  margin-left: 16px;
  cursor: pointer;
`

const USERS_QUERY = gql`
  {
    users {
      id
      firstname
      lastname
      email
      isAdmin
    }
  }
`

const DELETE_USER_MUTATION = gql`
  mutation($email: String!) {
    deleteUser(email: $email)
  }
`

const Users = ({ history }) => {
  return (
    <div>
      <Actions>
        <Link to="/admin/users/add">
          <Button>Hinzufügen</Button>
        </Link>
      </Actions>
      <Query query={USERS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>
          if (error) return <p>Fehler :(</p>

          return data.users.map(user => (
            <Item
              key={user.id}
              icon={<FontAwesomeIcon icon={['far', 'user']} />}
              title={`${user.firstname} ${user.lastname}`}
              meta={user.email}
              focus={user.isAdmin}
              actions={
                <div>
                  <Action
                    onClick={e => {
                      history.push(`/admin/users/${user.id}/edit`)
                    }}
                  >
                    <FontAwesomeIcon icon={['far', 'pen']} />
                  </Action>
                  <Mutation
                    mutation={DELETE_USER_MUTATION}
                    variables={{ email: user.email }}
                    refetchQueries={[{ query: USERS_QUERY }]}
                  >
                    {deleteUser => (
                      <Action
                        onClick={e => {
                          e.preventDefault()
                          if (
                            window.confirm(
                              'Soll der Benutzer wirklich gelöscht werden?'
                            )
                          ) {
                            deleteUser()
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'trash']} />
                      </Action>
                    )}
                  </Mutation>
                </div>
              }
            />
          ))
        }}
      </Query>
    </div>
  )
}

export default Users
